<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <campos-filtros
          ref="refCamposFiltros"
          :campos="campos"
          @loading="loading = $event"
        />
      </v-col>
      <v-col cols="12">
        <v-btn
          color="primary"
          style="float: right"
          class="mx-0"
          @click="getDados"
        >
          Filtrar
        </v-btn>
      </v-col>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-file-document-edit-outline"
          inline
          class="px-5 py-4 ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">
              {{ tabela | toUpperCase }}
            </div>
          </template>
          <v-data-table
            :items="items"
            :headers="headers"
            :items-per-page="10"
            :loading="loading"
            :hide-default-footer="loading"
            :options="{ multiSort: false }"
            :sort-by="['id']"
            loading-text="Carregando tabela"
            no-data-text="Nenhuma linha disponível"
            no-results-text="Nenhuma linha correspondente encontrado"
            dense
            class="mt-10"
          >
            <template v-slot:top>
              <div class="d-flex justify-end my-3">
                <v-btn
                  color="primary"
                  class="mx-0 mt-n12"
                  @click="openNewRecordDialog"
                >
                  Nova linha
                </v-btn>
              </div>
              <v-alert
                dense
                outlined
                type="info"
                color="blue"
                class="mt-3 mb-3 py-1 px-1"
                v-if="!loading && limit < totalLinhas"
              >
                <span>
                  Esta tela exibe no máximo {{ limit | parseNumberToIntegerBR }} linhas 
                  de um total de {{ totalLinhas | parseNumberToIntegerBR }}
                  linhas da tabela. Sua consulta retornou 
                  {{ quantidadeLinhas | parseNumberToIntegerBR }} linhas.
                  Para ver outras linhas, utilize os filtros.
                </span>
              </v-alert>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-left">
                  <v-btn
                    color="error"
                    fab
                    icon
                    x-small
                    @click="openDialogDelete(item.id)"
                  >
                    <v-icon small>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
                <td>
                  {{ item.id }}
                </td>
                <td
                  v-for="(dado, coluna) in item"
                  :key="coluna + item.id"
                  v-if="coluna !== 'id'"
                >
                  <editar-dado-tabela
                    :tabela="tabela"
                    :coluna="coluna"
                    :id="item.id"
                    :tipoDado="getTipoColuna(coluna)"
                    :dadoInicial="dado"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
    <dialog-delete
      :dialog.sync="dialogDelete"
      v-if="dialogDeleteData"
      @submitTriggered="excluirLinha"
    >
      <template slot="title">
        Excluir linha #{{ dialogDeleteData.id | parseNumberToIntegerBR }}
      </template>
      <template slot="body">
        Tem certeza que deseja excluir a linha #{{
          dialogDeleteData.id | parseNumberToIntegerBR
        }}
        da tabela {{ tabela | toUpperCase }}?
      </template>
    </dialog-delete>
    <dialog-nova-linha-tabela
      :dialog.sync="dialogNewRecord"
      :campos="campos"
      :tabela="tabela"
      @created="newRecordCreated"
    />
  </v-container>
</template>

<script>
import AtualizacoesTabelasService from '@/services/AtualizacoesTabelasService';

export default {
  name: 'AtualizacoesTabelasVisualizar',
  components: {
    CamposFiltros: () => import('@/components/relatorios/CamposFiltros'),
    DialogDelete: () => import('@/components/general/DialogDelete'),
    EditarDadoTabela: () =>
      import('@/components/geracao-bdgd/atualizacoes-tabelas/EditarDadoTabela'),
    DialogNovaLinhaTabela: () =>
      import(
        '@/components/geracao-bdgd/atualizacoes-tabelas/DialogNovaLinhaTabela'
      )
  },
  computed: {
    tabela() {
      return this.$route.params.tabela;
    }
  },
  data() {
    return {
      items: [],
      headers: [],
      loading: false,
      campos: [],
      dialogDelete: false,
      dialogDeleteData: null,
      dialogNewRecord: false,
      limit: 1000,
      totalLinhas: 0,
      quantidadeLinhas: 0
    };
  },
  mounted() {
    this.getDados();
  },
  methods: {
    getDados() {
      this.loading = true;

      const filtros = this.$refs.refCamposFiltros
        ? this.$refs.refCamposFiltros.exportCampos()
        : {};

      AtualizacoesTabelasService.getDados(this.tabela, this.limit, filtros)
        .then(({ data }) => {
          this.items = data.dados;

          const colunas = data.colunas.filter((c) => c.nome !== 'id');

          this.headers = [
            {
              sortable: false,
              text: null,
              value: 'actions',
              class: 'pl-4'
            },
            {
              text: 'gid',
              value: 'id'
            },
            ...colunas.map((coluna) => {
              return {
                text: coluna.nome,
                value: coluna.nome,
                divider: false,
                type: coluna.tipo
              };
            })
          ];

          this.campos = colunas.map((coluna) => ({
            column: coluna.nome,
            type: coluna.tipo
          }));

          this.totalLinhas = data.total;
          this.quantidadeLinhas = data.quantidade;
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error(
            'Erro ao recuperar as linhas para a tabela ' +
              this.tabela.toUpperCase() +
              '.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openDialogDelete(id) {
      this.dialogDeleteData = { id };
      this.dialogDelete = true;
    },
    excluirLinha() {
      const id = this.dialogDeleteData.id;
      AtualizacoesTabelasService.deleteById(this.tabela, id)
        .then(() => {
          this.items = this.items.filter((item) => item.id !== id);
          this.quantidadeLinhas--;
          this.totalLinhas--;
          this.dialogDelete = false;
          this.$toast.success(`Linha #${id} removida com sucesso.`, '', {
            position: 'topRight'
          });
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(
            `Ocorreu um erro ao tentar remover a linha #${id} da tabela.`,
            '',
            { position: 'topRight' }
          );
        });
    },
    getTipoColuna(coluna) {
      return this.campos.find((campo) => campo.column === coluna)?.type;
    },
    openNewRecordDialog() {
      this.dialogNewRecord = true;
    },
    newRecordCreated(record) {
      this.items.push(record);
      this.quantidadeLinhas++;
      this.totalLinhas++;
    }
  }
};
</script>
